import styled from "styled-components";

const Styling = styled.div`
  .intro {
    background: var(--white);
    padding: 10rem 2rem 6rem 2rem;

    @media only screen and (min-width: 350px) {
      padding: 10rem 4rem 6rem 4rem;
    }

    @media only screen and (min-width: 1024px) {
      padding: 16rem 8rem 10rem 8rem;
    }

    .intro_buttons {
      .capsule_button:first-child {
        margin-right: 2rem;

        @media only screen and (max-width: 768px) {
          margin-bottom: 1rem;
        }
      }

      .capsule_button {
        ${({ theme }) => theme.tomato("regular")}
        font-size:clamp(1.4rem, 2vw, 2rem);

        @media only screen and (max-width: 768px) {
          width: 100%;
          justify-content: center;
        }
      }
    }

    .intro_text {
      margin-bottom: 2rem;
      line-height: 2rem;
      font-size: 1.5rem;

      @media only screen and (min-width: 768px) {
        margin-bottom: clamp(6rem/2, 6vw/2, 20rem/2);
        line-height: 2.5rem;
        font-size: 1.6rem;
        max-width: 60%;
      }
    }
  }

  .main-title {
    ${({ theme }) => theme.tomato("regular")}
    text-transform:uppercase;
    letter-spacing: -0.15rem;
    position: relative;
    width: auto;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    font-size: 2.5rem;
    line-height: 2rem;
    margin-bottom: 2rem;

    @media only screen and (min-width: 350px) {
      font-size: 3rem;
      line-height: 2.6rem;
      letter-spacing: -0.2rem;
    }

    @media only screen and (min-width: 420px) {
      font-size: 4rem;
      line-height: 3rem;
      letter-spacing: -0.3rem;
    }

    @media only screen and (min-width: 610px) {
      font-size: 5rem;
      line-height: 4rem;
      letter-spacing: -0.4rem;
    }

    @media only screen and (min-width: 768px) {
      margin-bottom: 4rem;
    }

    @media only screen and (min-width: 800px) {
      font-size: 6rem;
      line-height: 5rem;
    }

    @media only screen and (min-width: 1024px) {
      font-size: clamp(6rem, 8vw, 12rem);
      line-height: clamp(6rem/1.15, 8vw/1.15, 12rem/1.15);
      margin-bottom: clamp(6rem/2, 6vw/2, 20rem/2);
    }

    span {
      width: 100%;
      align-items: center;
      display: flex;
    }

    .arrow {
      width: 1em;
      height: 1em;
      transform: scale(0.6) translateX(20%) translateY(20%);
      margin-right: 1rem;

      path {
        fill: var(--black);
      }
    }
  }

  .programmes-wrapper {
    width: 100%;
    position: relative;
    overflow: hidden;
    background: var(--white);
    padding: 4rem 2rem;

    @media only screen and (min-width: 350px) {
      padding: 4rem;
    }

    @media only screen and (min-width: 1024px) {
      padding: 8rem;
    }

    .programmes {
      width: 100%;
      position: relative;
      margin-bottom: clamp(2rem, 4vw, 8rem);
      text-align: center;
      display: grid;
      grid-gap: 2rem;

      @media only screen and (min-width: 680px) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media only screen and (min-width: 1024px) {
        grid-template-columns: repeat(4, 1fr);
      }

      .featured_programme {
        background: var(--white);
        border-radius: 0.75rem;
        color: var(--black);
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        z-index: 10;
        position: relative;

        .featured_programme_link {
          text-decoration: none;
          color: var(--black);
          display: flex;
          justify-content: center;
          align-content: flex-start;
          flex-wrap: wrap;
          padding: 4rem 2rem 6rem 2rem;
          position: relative;

          .icon {
            max-width: 100px;
            position: relative;
            margin-bottom: 2rem;
          }

          .programme_title {
            font-size: clamp(2.5rem, 1.5vw, 4rem);
            ${({ theme }) => theme.tomato("regular")}
            text-transform:uppercase;
            letter-spacing: -0.1rem;
            margin-bottom: 2rem;
            width: 100%;
            position: relative;

            span {
              width: 100%;
              position: relative;
              display: block;
            }
          }

          .capsule {
            background: var(--green);
            /* line-height: 0; */
            padding: 1rem 1.5rem;
            position: relative;
            font-size: 1.4rem;
            display: flex;
            align-items: center;
            border-radius: 1.5rem;
            margin-bottom: 2rem;

            svg {
              margin-right: 0.5rem;
              margin-top: -0.2rem;
            }
          }

          .programme_text {
            font-size: clamp(1.5rem, 1vw, 2rem);
            ${({ theme }) => theme.tomato("regular")}
            letter-spacing: -0.1rem;
            margin-bottom: 2rem;
            position: relative;
            line-height: clamp(1.5rem * 1.5, 1vw * 1.5, 2rem * 1.5);
            position: relative;
          }

          .programme_list {
            position: relative;
            width: 100%;
            border-top: 1px solid var(--grey_mid);
            padding-top: 2rem;
            margin-bottom: 4rem;

            li {
              margin-bottom: 1rem;
              ${({ theme }) => theme.telegraf("bold")}
            }
          }

          .capsule_button {
            align-self: flex-end;
          }

          &:hover {
            .capsule_button {
              background: var(--white);
              color: var(--black);
            }
          }
        }
      }
    }
  }

  .bg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 0;
    background: var(--black);

    .gatsby-image-wrapper {
      opacity: 0.3;
    }
  }

  .courses-wrapper {
    width: 100%;
    background: var(--white);
    position: relative;
    padding-top: 4rem;
    padding-bottom: 2rem;

    @media only screen and (min-width: 1024px) {
      padding-top: 8rem;
      padding-bottom: 0;
    }

    &.foundation {
      padding-top: 0;

      @media only screen and (min-width: 1024px) {
        padding-top: 0;
      }
    }

    &.dj {
      @media only screen and (max-width: 768px) {
        .courses {
          border-bottom: 0;
        }
      }
    }

    .courses {
      width: 100%;
      display: grid;
      grid-gap: 2rem;
      grid-template-columns: repeat(1, 1fr);
      background: var(--white);
      /* border-bottom:1px solid rgba(0,0,0,.2); */
      padding: 0 2rem 0rem 2rem;

      @media only screen and (min-width: 350px) {
        padding: 0 4rem 2rem 4rem;
      }

      @media only screen and (min-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media only screen and (min-width: 1024px) {
        padding: 0 8rem 8rem 8rem;
        grid-template-columns: repeat(3, 1fr);
      }

      .gatsby-image-wrapper {
        @media only screen and (max-width: 768px) {
          height: 0;
          padding-bottom: 46%;
        }

        @media only screen and (max-width: 550px) {
          height: auto;
          padding-bottom: 0;
        }
      }
    }

    h2 {
      width: 100%;
      margin-bottom: 2rem;
      position: relative;
      padding: 0 0 0 2rem;
      ${({ theme }) => theme.tomato("regular")}
      text-transform:uppercase;
      font-size: 1.8rem;
      line-height: 1.3rem;

      @media only screen and (min-width: 350px) {
        padding: 0 0 0 4rem;
      }

      @media only screen and (min-width: 420px) {
        font-size: 2rem;
        line-height: 1.5rem;
      }

      @media only screen and (min-width: 610px) {
        font-size: 2.5rem;
        line-height: 2rem;
      }

      @media only screen and (min-width: 800px) {
        font-size: 3rem;
        line-height: 2.5rem;
      }

      @media only screen and (min-width: 1024px) {
        font-size: 4rem;
        padding: 0 0 0 8rem;
        margin-bottom: 4rem;
      }
    }
  }
`;

export { Styling };

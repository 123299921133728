
import styled from 'styled-components'

const Styling = styled.div`
border-radius: 10px;
overflow: hidden;

    a{
    color:var(--black);
    text-decoration: none;
        

        &:hover{
            .image-wrapper{
            border-radius: 0;
                .gatsby-image-wrapper{
                opacity: 0.8;
                transform: scale(1.1);
                }
            }

            .info{
            background:var(--black);
            color:var(--white);
            padding-left:2rem;
            }
        }

        .image-wrapper{
        overflow: hidden;
        position: relative;
        background:var(--black);
        border-radius: 0 0 10px 10px;
        ${({theme}) => theme.easing('0.5')}  

            .gatsby-image-wrapper{
            ${({theme}) => theme.easing('0.5')}  
            }

            .price{
            position: absolute;
            bottom:2rem;
            right:2rem;
            z-index: 10;
            background:var(--white);
            color:var(--black);
            padding:1rem;
            letter-spacing: -0.05rem;
            font-size:clamp(1.2rem, 0.75vw, 2rem);
            border-radius: 5px;
            }
        }
        
        .info{
        padding:2rem 0;
        ${({theme}) => theme.easing('0.5')}  

            .course_title{
            text-transform:uppercase;
            ${({theme}) => theme.tomato('regular')}  
            font-size: 1.8rem;
            margin-bottom: 1rem;

                @media only screen and (min-width: 1024px) {
                font-size:clamp(2rem, 1.2vw, 3rem);
                margin-bottom: 2rem;
                }
            }
        }
    }
`

export { Styling }
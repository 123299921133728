import React from 'react'
import { Styling } from './styled.js'
import {Link} from 'gatsby'
import {GatsbyImage,  getImage} from 'gatsby-plugin-image';
import formatIntructors from '../../../utils/formatInstructors'

export default function ProductionCourseCard({course}) {
    const theImage = getImage(course?.courseBuilder?.courseThumbnailImage?.localFile);
    return (
        <Styling>
            <Link to={`/programmes/on-demand/${course.slug}`}>
                <div className="image-wrapper">
                    <GatsbyImage image={theImage} className="image" alt={'test'} />
                    <div className="price">£{course.courseBuilder.price}</div>
                </div>
                <div className="info">
                    <h1 className='course_title'>{course.title}</h1>
                    <div className="course_instructors">with {formatIntructors(course)}</div>
                </div>
            </Link>
        </Styling>
    )
}

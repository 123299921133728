const formatIntructors = (course) => {
    const instructorsArray = course.courseBuilder.instructors
    

    const instructors = instructorsArray.map((instructor, index) => {
        if(instructorsArray.length < 2){
            return instructor.instructor.title
        }
        else if(index !== instructorsArray.length - 1){
            return instructor.instructor.title+' // '
        }else{
            return instructor.instructor.title
        }
    })
    return instructors
}

export default formatIntructors
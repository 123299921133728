import React from "react";
import { graphql } from "gatsby";
import { Link } from "gatsby";
import ArrowDown from "../../images/arrow_right_black.inline.svg";
import { Styling } from "../../styles/pages/programmes-production.styled";
import ProductionCourseCard from "../../components/reusable/ProductionCourseCard";
import parse from "html-react-parser";
import Seo from "../../components/global/seo";

export default function Programmes(props) {
  return (
    <Styling>
      <Seo title={props.data.wpPage.seo.title} description={props.data.wpPage.seo.metaDesc} />
      <div className="intro">
        <h2 className="main-title">
          <span>Toolroom</span>{" "}
          <span>
            <ArrowDown className="arrow title-arrow" /> Programmes
          </span>
        </h2>
        <div className="intro_text">
          {parse(props.data.wpPage.programme_archive_fields_production.introText)}
        </div>
        <div className="intro_buttons">
          <Link
            to="/programmes/interactive/"
            className={`capsule_button ${
              props.path === "/programmes/interactive/" ? "black active" : "white"
            }`}>
            Interactive Programmes
          </Link>
          <Link
            to="/programmes/on-demand/"
            className={`capsule_button ${
              props.path === "/programmes/on-demand/" ? "black active" : "white"
            }`}>
            On-Demand Tutorials
          </Link>
        </div>
      </div>
      <div className="courses-wrapper artist">
        <h2>Artist Programmes</h2>
        <div className="courses">
          {props.data.artist.nodes.map((course, index) => {
            return (
              course?.acf_hide_course?.hideCourse !== true && (
                <ProductionCourseCard course={course} />
              )
            );
          })}
        </div>
      </div>
      <div className="courses-wrapper foundation">
        <h2>Foundation Series</h2>
        <div className="courses">
          {props.data.courses.nodes.map((course, index) => {
            return (
              course?.acf_hide_course?.hideCourse !== true && (
                <ProductionCourseCard course={course} />
              )
            );
          })}
        </div>
      </div>
      <div className="courses-wrapper dj">
        <h2>DJ Tuition</h2>
        <div className="courses">
          {props.data.dj.nodes.map((course, index) => {
            return (
              course?.acf_hide_course?.hideCourse !== true && (
                <ProductionCourseCard course={course} />
              )
            );
          })}
        </div>
      </div>
    </Styling>
  );
}

export const query = graphql`
  {
    wpPage(title: { eq: "On-Demand Tutorials" }) {
      title
      programme_archive_fields_production {
        introText
      }
      seo {
        metaDesc
        title
      }
    }

    courses: allWpCourse(
      sort: { fields: menuOrder, order: ASC }
      filter: { courseTypes: { nodes: { elemMatch: { name: { eq: "Production Tutorials" } } } } }
    ) {
      nodes {
        title
        slug
        acf_hide_course {
          hideCourse
        }
        courseBuilder {
          price
          courseThumbnailImage {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 1500, placeholder: BLURRED, formats: [AUTO, WEBP])
              }
            }
          }
          instructors {
            instructor {
              ... on WpInstructor {
                title
              }
            }
          }
        }
      }
    }

    artist: allWpCourse(
      sort: { fields: menuOrder, order: ASC }
      filter: { courseTypes: { nodes: { elemMatch: { name: { eq: "Artist" } } } } }
    ) {
      nodes {
        title
        slug
        acf_hide_course {
          hideCourse
        }
        courseBuilder {
          price
          courseThumbnailImage {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 1500, placeholder: BLURRED, formats: [AUTO, WEBP])
              }
            }
          }
          instructors {
            instructor {
              ... on WpInstructor {
                title
              }
            }
          }
        }
      }
    }

    dj: allWpCourse(
      sort: { fields: menuOrder, order: ASC }
      filter: { courseTypes: { nodes: { elemMatch: { name: { eq: "DJ" } } } } }
    ) {
      nodes {
        title
        slug
        acf_hide_course {
          hideCourse
        }
        courseBuilder {
          price
          courseThumbnailImage {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 1500, placeholder: BLURRED, formats: [AUTO, WEBP])
              }
            }
          }
          instructors {
            instructor {
              ... on WpInstructor {
                title
              }
            }
          }
        }
      }
    }
    degree: allWpCourse(
      filter: { courseTypes: { nodes: { elemMatch: { name: { eq: "Degree" } } } } }
    ) {
      nodes {
        title
        slug
        acf_hide_course {
          hideCourse
        }
        courseBuilder {
          price
          courseThumbnailImage {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 1500, placeholder: BLURRED, formats: [AUTO, WEBP])
              }
            }
          }
          instructors {
            instructor {
              ... on WpInstructor {
                title
              }
            }
          }
        }
      }
    }
  }
`;
